$( window ).on( "load", function() {
	$( window ).on( "scroll", function() {
		$( ".gallery-item-parallax" ).each( function() {
			let $this = $( this );
	
			if ( isFullyScrolledPast( $this, 150 ) ) {
				$this.find( ".gallery-hidden" ).addClass( "gallery-show" );
			} else {
				$this.find( ".gallery-hidden" ).removeClass( "gallery-show" );
			}
		} );
	} );
	
	function isFullyScrolledPast( element, offset ) {
		let elementTop = element.offset().top;
		let viewportTop = $( window ).scrollTop();
		
		return elementTop < viewportTop + offset;
	}
	
} );